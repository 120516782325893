<template>
  <div>
    <div class="filters">
      <div class="filter customer">
        <md-field v-if="showItemFilter" class="filter column">
          <label>Item Number</label>
          <md-input
            type="search"
            clearable
            placeholder="Search by Item#"
            @input="$emit('handleSearch', $event)"
          ></md-input>
        </md-field>

        <md-field v-if="showLineItemFilter" class="filter column">
          <label>Line Item Number</label>
          <md-input
            type="search"
            clearable
            placeholder="Search records by Item#"
            @input="$emit('handleSearch', $event)"
          ></md-input>
        </md-field>
      </div>
    </div>

    <md-card>
      <md-card-content>
        <div class="row-btns">
          <md-button
            v-if="showQCBtn && !selectedItems.length"
            class="qc-button"
            @click="showMultTable = !showMultTable"
          >
            {{ showMultTable ? "Close" : "Q/A Multiple Items" }}
          </md-button>

          <md-button
            v-else-if="showQCBtn && selectedItems.length"
            class="qc-button"
            style="background-color: green"
            @click="$emit('qc-mult', selectedItems)"
          >
            Q/A Selected Items
          </md-button>
        </div>
        <md-table
          v-if="!showMultTable"
          md-fixed-header
          :value="queriedData"
          class="paginated-table table-striped table-hover"
          id="orders-table"
        >
          <!-- <md-table-toolbar class="per-page" :style="{ marginBottom: '20px' }">
            <md-field>
              <label for="orders">Per page</label>
              <md-select v-model="pagination.perPage" name="orders">
                <md-option
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                  >{{ item }}</md-option
                >
              </md-select>
            </md-field>
          </md-table-toolbar> -->

          <md-table-row slot="md-table-row" slot-scope="{ item }">
            <md-table-cell
              v-for="(data, i) in tableInfo"
              :key="i"
              :md-label="data.label"
              :md-sort-by="data.key"
              >{{ item[data.key] }}</md-table-cell
            >
            <md-table-cell class="row-action-btns">
              <md-button
                @click="$emit('selected-content', item)"
                class="md-just-icon md-warning md-simple"
              >
                View
              </md-button>
              <md-button
                v-if="showQCBtn"
                class="qc-button"
                @click="$emit('open-qc', item)"
              >
                Q/A Item
              </md-button>
            </md-table-cell>
          </md-table-row>
        </md-table>
        <md-table
          v-else-if="showQCBtn && showMultTable"
          v-model="queriedData"
          md-card
          @md-selected="onSelected"
        >
          <md-table-row
            slot="md-table-row"
            slot-scope="{ item }"
            :md-disabled="item.QCCompleted"
            md-selectable="multiple"
            md-auto-select
          >
            <md-table-cell md-label=""></md-table-cell>
            <md-table-cell
              v-for="(data, i) in tableInfo"
              :key="i"
              :md-label="data.label"
              :md-sort-by="data.key"
              >{{ item[data.key] }}</md-table-cell
            >
          </md-table-row>
        </md-table>
      </md-card-content>
      <md-card-actions md-alignment="space-between">
        <div class>
          <p class="card-category">
            Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
          </p>
        </div>
        <Pagination
          class="pagination-no-border pagination-success"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
        ></Pagination>
        <md-table-toolbar class="per-page" :style="{ marginBottom: '20px' }">
          <md-field>
            <label for="orders">Per page</label>
            <md-select v-model="pagination.perPage" name="orders">
              <md-option
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
                >{{ item }}</md-option
              >
            </md-select>
          </md-field>
        </md-table-toolbar>
      </md-card-actions>
    </md-card>
  </div>
</template>

<script>
import Pagination from "./Pagination.vue";
import Modal from "./Modal.vue";
export default {
  components: { Pagination, Modal },
  props: {
    showQCBtn: Boolean,
    showFilter: Boolean,
    showItemFilter: Boolean,
    showLineItemFilter: Boolean,
    tableData: {
      type: Array,
      default: () => [],
    },
    tableInfo: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      // if (this.searchedData.length > 0) {
      //   result = this.searchedData;
      // }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.tableData.length;
      // return this.searchedData.length > 0
      //   ? this.searchedData.length
      //   : this.tableData.length;
    },
  },
  data() {
    return {
      showDialog: false,
      showMultTable: false,
      selectedItems: [],
      pagination: {
        perPage: 250,
        currentPage: 1,
        perPageOptions: [250, 500, 1000],
        total: 0,
      },
    };
  },
  methods: {
    handleEdit() {
      this.showDialog = true;
    },
    onSelected(data) {
      this.selectedItems = data;
    },
    getAlternateLabel(count) {
      let plural = "";
      if (count > 1) plural = "s";
      return `${count} item${plural} selected`;
    },
  },
};
</script>

<style scoped>
.container {
  display: flex !important;
}

table {
  display: flex !important;
  flex-direction: column !important;
}

.md-table {
  height: 220px !important;
}

.row-btns {
  display: flex;
  flex-direction: row-reverse;
}

.row-action-btns {
  width: 14rem;
}

.md-table-alternate-header {
  position: static !important;
}
</style>
