<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <!-- Filter Section -->
      <md-card>
        <md-card-content>
          <md-field class="filter column">
            <p>Search by:</p>
            <div class="search-options">
              <ul style="display: inline-flex">
                <li
                  v-for="(r, i) in [
                    { label: 'Order Number', value: 'on' },
                    { label: 'PO #', value: 'po' },
                    { label: 'SKU', value: 'sku' },
                    { label: 'Certificates', value: 'cert' },
                  ]"
                  :key="i"
                >
                  <input
                    type="radio"
                    v-model="selectedSearchBy"
                    :value="r.value"
                  />
                  {{ r.label }}
                </li>
              </ul>
            </div>
            <md-input
              type="search"
              clearable
              placeholder="Search records here... Press enter to search"
              v-model="searchVal"
              @keypress="handleSearch($event)"
            ></md-input>
          </md-field>
          <md-button @click="handleSearch('click')" class="text-btn"
            >Search</md-button
          >
          <md-button class="outline-btn" @click="clearFilters"
            >Clear
          </md-button>
        </md-card-content>
      </md-card>

      <md-card class="light">
        <md-card-content>
          <div class="filters">
            <div
              v-if="activeUser.isAdmin || activeUser.isSuperAdmin"
              class="filter customer"
            >
              <p>Clients</p>
              <v-select
                :appendToBody="true"
                :deselectFromDropdown="true"
                v-model="filterBy.client"
                :options="clients"
                label="name"
                :reduce="(t) => t.name"
                :closeOnSelect="true"
              >
              </v-select>
            </div>

            <div v-if="!activeUser.customerID" class="filter customer">
              <p>Customers</p>
              <v-select
                :appendToBody="true"
                :deselectFromDropdown="true"
                v-model="filterBy.customers"
                :options="customers"
                label="name"
                :reduce="(t) => t.name"
                :closeOnSelect="true"
              >
              </v-select>
            </div>

            <div class="filter customer">
              <p>End Task</p>
              <v-select
                :appendToBody="true"
                :deselectFromDropdown="true"
                v-model="filterBy.status"
                :options="['Completed', 'Processing', 'New Intake/Incomplete']"
                :closeOnSelect="true"
              >
              </v-select>
            </div>

            <div class="filter">
              <p>Start From:</p>
              <md-datepicker
                class="datepicker"
                @input="validateDateRange('startDate')"
                v-model="filterBy.startDate"
              />
            </div>
            <div class="filter">
              <p>End To:</p>
              <md-datepicker
                class="datepicker"
                @input="validateDateRange('endDate')"
                v-model="filterBy.endDate"
              />
            </div>
            <div class="filter">
              <p>Show Orders with items that:</p>
              <span>Failed </span>
              <md-checkbox v-model="filterBy.showFailedItems" />

              <span>Passed-W/R </span>
              <md-checkbox v-model="filterBy.showPassedWithRepair" />

              <span>Are Certificates </span>
              <md-checkbox v-model="filterBy.showCertificateOrders" />
            </div>
            <div
              class="filter filter-btn"
              style="width: 200px; bottom: 5px; position: absolute; right: 0"
            >
              <md-button class="text-btn" @click="handleFilter"
                >Filter</md-button
              >
              <md-button class="outline-btn" @click="clearFilters"
                >Clear
              </md-button>
            </div>
          </div>
        </md-card-content>
      </md-card>

      <!-- Data Table Section -->
      <md-card>
        <md-card-content>
          <div class="btn-row stats_holder">
            <md-button
              :class="
                showExportOptions ? 'md-accent' : 'md-primary md-round md-small'
              "
              @click="
                () => {
                  if (showExportOptions) {
                    showExportOptions = false;
                    showSelectableTable = false;
                    selectedExportData = [];
                  } else {
                    showExportOptions = true;
                  }
                }
              "
            >
              {{ showExportOptions ? "Cancel" : "Export" }}</md-button
            >
            <div v-if="showExportOptions">
              <md-button @click="exportCSV('all')" class="md-primary"
                >Export All Table Data</md-button
              >
              <md-button
                class="md-primary"
                @click="showSelectableTable = !showSelectableTable"
                >Export Selected Items</md-button
              >
            </div>
            <!-- <md-button
              class="md-primary md-round md-small"
              @click="promptFileUpload"
              >Import</md-button
            >
            <input
              style="display: none"
              id="inputFile"
              type="file"
              @input="importCSV"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            /> -->
          </div>
          <md-table
            v-if="!showSelectableTable"
            md-fixed-header
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            class="paginated-table table-striped table-hover"
            id="orders-table"
          >
            <!-- <md-table-toolbar :style="{ marginBottom: '20px' }">
              <md-field>
                <label for="orders">Per page</label>
                <md-select v-model="pagination.perPage" name="orders">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                    >{{ item }}</md-option
                  >
                </md-select>
              </md-field>
            </md-table-toolbar> -->

            <md-table-row slot="md-table-row" slot-scope="{ item }"
              ><md-table-cell md-label="Date/Time" md-sort-by="Timestamp">{{
                item.Timestamp | format_date_time
              }}</md-table-cell>
              <md-table-cell md-label="Order #" md-sort-by="OrderNum">{{
                item.OrderNum
              }}</md-table-cell>

              <md-table-cell
                v-if="activeUser.isAdmin || activeUser.isSuperAdmin"
                md-label="Client"
                md-sort-by="Client"
                >{{ item.Client }}</md-table-cell
              >
              <md-table-cell
                v-if="!activeUser.customerID"
                md-label="Customer"
                md-sort-by="Customer"
              >
                <div class="content">
                  {{ item.Customer }}
                </div>
              </md-table-cell>
              <md-table-cell md-label="Purchase Orders">
                <div class="item-sku-wrapper">
                  <ol style="padding: 0">
                    <li v-for="data in item.PurchaseOrders" :key="data">
                      {{ data }}
                    </li>
                  </ol>
                </div>
              </md-table-cell>
              <md-table-cell md-label="SKUs">
                <div class="item-sku-wrapper">
                  <ol style="padding: 0">
                    <li v-for="data in item.ItemSKUs" :key="data">
                      {{ data }}
                    </li>
                  </ol>
                </div>
              </md-table-cell>
              <md-table-cell md-label="Certificates">
                <div class="item-sku-wrapper">
                  <ol style="padding: 0" v-if="item.ItemCerts">
                    <li v-for="data in item.ItemCerts" :key="data">
                      {{ data }}
                    </li>
                  </ol>
                  <div v-else>N/A</div>
                </div>
              </md-table-cell>
              <md-table-cell md-label="Qty" md-sort-by="Quantity">{{
                item.Quantity
              }}</md-table-cell>
              <md-table-cell md-label="Failed" md-sort-by="FailedItems">{{
                item.FailedItems
              }}</md-table-cell>

              <md-table-cell
                md-label="W/Repairs"
                md-sort-by="PassedWithRepairsItems"
                >{{ item.PassedWithRepairsItems || 0 }}</md-table-cell
              >
              <md-table-cell md-label="Skipped" md-sort-by="SkippedItems">{{
                item.SkippedItems || 0
              }}</md-table-cell>
              <md-table-cell md-label="End Task" md-sort-by="">{{
                item.Status
              }}</md-table-cell>
              <md-table-cell
                v-if="activeUser.isAdmin || activeUser.isSuperAdmin"
                md-label="Authenticated By"
                md-sort-by=""
              >
                <div class="qa-associate-wrapper">
                  <md-button
                    class="md-just-icon md-warning md-flat"
                    @click.native="handleAuthViewModal(item)"
                  >
                    <md-icon style="color: blue !important">groups_2 </md-icon>
                  </md-button>
                </div>
                <md-tooltip> View Who Authenticated This Order </md-tooltip>
              </md-table-cell>
              <!-- <md-table-cell md-label="Date/Time Completed" md-sort-by="CompletedAt">{{
               item.CompletedAt ? (item.CompletedAt | format_date_time) : ''
              }}</md-table-cell> -->

              <md-table-cell md-label="Controls">
                <div class="action-btns">
                  <md-button
                    v-if="activeUser.isAdmin || activeUser.isSuperAdmin"
                    class="md-just-icon md-warning md-flat"
                    @click.native="handleViewModal(item)"
                  >
                    <md-icon style="color: green !important"
                      >visibility</md-icon
                    >
                    <md-tooltip> View More Details </md-tooltip>
                  </md-button>

                  <md-button
                    class="md-just-icon md-warning md-flat"
                    @click.native="showFailedItemReport(item)"
                  >
                    <md-icon style="color: gray !important">
                      receipt_long
                    </md-icon>
                    <md-tooltip> View Report </md-tooltip>
                  </md-button>
                </div>
              </md-table-cell>
            </md-table-row>
          </md-table>

          <SelectableTable
            v-else
            @onSelected="onSelected"
            :tableData="tableData"
            :tableInfo="exportItemsInfo"
          >
            <template v-slot:btns>
              <md-button
                v-if="selectedExportData.length"
                class="qc-button"
                @click="exportCSV('selected')"
              >
                Export
              </md-button>
            </template>
          </SelectableTable>
        </md-card-content>
        <md-divider></md-divider>
        <md-card-actions md-alignment="space-between">
          <div
            class="per-page"
            :style="{ marginBottom: '20px', marginTop: '20px' }"
          >
            <label for="orders">Per page</label>
            <div>
              <select
                style="border: none !important"
                v-model="pagination.perPage"
                name="orders"
              >
                <option
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                  {{ item }}
                </option>
              </select>
            </div>
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <div
            v-if="activeUser.isAdmin || activeUser.isSuperAdmin"
            class="footer"
          >
            <md-button class="qc-button" @click="showAuthModal = true">
              Authenticate
            </md-button>
          </div>
          <Pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
            @update-table="fetchData"
            :allowClick="Boolean(tableData.length + searchedData.length)"
          ></Pagination>
        </md-card-actions>
      </md-card>

      <!-- Table Modal for when a user clicks on an order  -->
      <TableModal
        @qc-mult="handleMultipleItemsQA"
        :showFilterForItemTable="true"
        :showItemFilter="true"
        :orderLineStatus.sync="selectedOrderLineStatus"
        :lineItems.sync="allLineItems"
        :showDialog.sync="showDialog"
        :lineItemsInfo.sync="lineItemsInfo"
        :itemsInfo="itemsInfo"
        @selectedLineItemAction="selectedLineItemAction"
        @selectedItemAction="selectedItemAction"
        @close-modal="closeTableModal()"
        @open-qc="openQC"
        @handleSearchForIndividualItem="handleSearchForIndividualItem"
      >
      </TableModal>

      <!-- Modal for Individual Line Items   -->
      <Modal
        :showBackBtn="true"
        @back="goBack('showModalContentForLineItem', 'showDialog')"
        @close="showModalContentForLineItem = false"
        :showDialog="showModalContentForLineItem"
      >
        <template v-slot:modalContent>
          <md-card>
            <md-card-content>
              <div class="row">
                <md-field style="min-width: fit-content">
                  <label>Customer</label>
                  <md-input disabled :value="selectedOrder.Customer"></md-input>
                </md-field>
                <md-field style="max-width: 120px">
                  <label>Order #</label>
                  <md-input disabled :value="selectedOrder.OrderNum"></md-input>
                </md-field>
                <md-field>
                  <label>Date/Time</label>
                  <md-input
                    disabled
                    :value="selectedOrder.Timestamp | format_date_time"
                  ></md-input>
                </md-field>
                <md-field style="max-width: 70px">
                  <label>QTY</label>
                  <md-input
                    disabled
                    :value="selectedLineItem.Quantity"
                  ></md-input>
                </md-field>
              </div>
              <div>
                <p>Description</p>
                <md-field>
                  <md-textarea
                    @input="showChangesBtn = true"
                    v-model="selectedLineItem.Description"
                  ></md-textarea>
                </md-field>
                <md-button
                  class="qc-button"
                  v-if="showChangesBtn"
                  @click="saveDescription(selectedLineItem)"
                  >Save Changes</md-button
                >
              </div>
            </md-card-content>
            <md-divider></md-divider>
            <md-card-content>
              <Table
                :tableData.sync="allItemsForSelectedLine"
                :tableInfo="itemsInfo"
                @selected-content="selectedItemAction"
              ></Table>
            </md-card-content>
          </md-card>
        </template>
      </Modal>
      <!-- Modal for Individual Item  -->

      <Modal
        :showBackBtn="true"
        @back="goBack('showModalContentForItem', 'showDialog')"
        :showDialog="showModalContentForItem"
        @close="showModalContentForItem = false"
      >
        <template v-slot:modalContent>
          <md-card>
            <md-card-content>
              <div class="row">
                <md-field>
                  <label>Order #</label>
                  <md-input disabled :value="selectedOrder.OrderNum"></md-input>
                </md-field>
                <md-field>
                  <label>SKU</label>
                  <md-input
                    disabled
                    v-if="selectedItem.ClientSKU"
                    :value="selectedItem.ClientSKU"
                  ></md-input>
                  <md-input
                    disabled
                    v-if="selectedItem.SKU"
                    :value="selectedItem.SKU"
                  ></md-input>
                </md-field>
                <md-field>
                  <label>Item Type</label>
                  <md-input disabled :value="selectedItem.Type"></md-input>
                </md-field>
              </div>
              <div style="margin-bottom: 16px !important">
                <img
                  :style="{
                    cursor: '-moz-zoom-in',
                    cursor: '-webkit-zoom-in',
                    cursor: 'zoom-in',
                  }"
                  @click="handleImageModal(selectedItem.ImageURL)"
                  :src="selectedItem.ImageURL"
                  alt=""
                />
              </div>
            </md-card-content>
          </md-card>
        </template>
        <template v-slot:dialog-actions>
          <md-button
            @click="
              (showItemImagesModal = true), (selectedItemType = 'individual')
            "
            class="btn all-text-btn"
            >Add New Photo</md-button
          >
          <md-button class="qc-button" @click="openQC(selectedItem)">
            Q/A Item
          </md-button>
        </template>
      </Modal>

      <!-- Modal for Image View   -->

      <Modal
        @close="
          () => {
            (showImageModal = false), (imageView = null);
          }
        "
        :showSaveBtn="false"
        :showDialog="showImageModal"
      >
        <template v-slot:modalContent>
          <div class="image-container">
            <img
              style="width: 100%"
              @click="handleImageModal(selectedItem.ImageURL)"
              :src="selectedItem.ImageURL"
              alt=""
            />
          </div>
        </template>
      </Modal>

      <!-- Modal for Q/A   -->
      <Modal
        @save="handleQCChanges"
        @close="clearQCResponses"
        :showBackBtn="false"
        :showSaveBtn="true"
        :showDialog="showQCModal"
      >
        <template v-slot:modalContent>
          <div class="header">
            <h3>Cert#: {{ selectedItem.CertNum || selectedItem.ItemNum }}</h3>
          </div>
          <md-card>
            <md-card-content>
              <div class="md-title">Notes:</div>
              <md-field>
                <md-textarea v-model="qcResponses.notes"></md-textarea>
              </md-field>
            </md-card-content>
          </md-card>
          <div class="container qc">
            <md-card class="left">
              <md-card-content>
                <table class="qc">
                  <tr class="qc-title">
                    <th class="qc">Overall</th>
                  </tr>
                  <tr>
                    <td>
                      <input
                        v-model="qcResponses.overall.a1.value"
                        @input="leftQCPanelInit = true"
                        type="checkbox"
                      />
                    </td>
                    <td>Product Status - if listed as Pre-Launch, do not QC</td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        v-model="qcResponses.overall.a2.value"
                        @input="leftQCPanelInit = true"
                        type="checkbox"
                      />
                    </td>
                    <td>Product matches photo</td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        v-model="qcResponses.overall.a3.value"
                        @input="leftQCPanelInit = true"
                        type="checkbox"
                      />
                    </td>
                    <td>Other</td>
                  </tr>
                  <tr class="qc-title">
                    <th class="qc">Stones</th>
                    <th></th>
                    <th></th>
                  </tr>
                  <tr>
                    <td>
                      <input
                        v-model="qcResponses.stones.a1.value"
                        @input="leftQCPanelInit = true"
                        type="checkbox"
                      />
                    </td>
                    <td>Stone count</td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        v-model="qcResponses.stones.a2.value"
                        @input="leftQCPanelInit = true"
                        type="checkbox"
                      />
                    </td>
                    <td>Stone size</td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        v-model="qcResponses.stones.a3.value"
                        @input="leftQCPanelInit = true"
                        type="checkbox"
                      />
                    </td>
                    <td>Stone clarity</td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        v-model="qcResponses.stones.a4.value"
                        @input="leftQCPanelInit = true"
                        type="checkbox"
                      />
                    </td>
                    <td>Stone color</td>
                  </tr>
                  <tr class="qc-title">
                    <th class="qc">Rings</th>
                  </tr>
                  <tr>
                    <td>
                      <input
                        v-model="qcResponses.rings.a1.value"
                        @input="leftQCPanelInit = true"
                        type="checkbox"
                      />
                    </td>
                    <td>Size</td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        v-model="qcResponses.rings.a2.value"
                        @input="leftQCPanelInit = true"
                        type="checkbox"
                      />
                    </td>
                    <td>Shank height/width</td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        v-model="qcResponses.rings.a3.value"
                        @input="leftQCPanelInit = true"
                        type="checkbox"
                      />
                    </td>
                    <td>Rhodium + color (if applicable)</td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        v-model="qcResponses.rings.a4.value"
                        @input="leftQCPanelInit = true"
                        type="checkbox"
                      />
                    </td>
                    <td>Prong Count</td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        v-model="qcResponses.rings.a5.value"
                        @input="leftQCPanelInit = true"
                        type="checkbox"
                      />
                    </td>
                    <td>Metal Type</td>
                  </tr>
                  <tr class="qc-title">
                    <th class="qc">Earrings</th>
                  </tr>
                  <tr>
                    <td>
                      <input
                        v-model="qcResponses.earrings.a1.value"
                        @input="leftQCPanelInit = true"
                        type="checkbox"
                      />
                    </td>
                    <td>Length/Width</td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        v-model="qcResponses.earrings.a2.value"
                        @input="leftQCPanelInit = true"
                        type="checkbox"
                      />
                    </td>
                    <td>Rhodium + color (if applicable)</td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        v-model="qcResponses.earrings.a3.value"
                        @input="leftQCPanelInit = true"
                        type="checkbox"
                      />
                    </td>
                    <td>Backing type</td>
                  </tr>
                </table>
              </md-card-content>
            </md-card>
            <md-card class="right">
              <md-card-content>
                <table class="qc-2">
                  <tr class="qc-title">
                    <th class="qc-2-title">Rejection Reasons</th>
                  </tr>
                  <tr>
                    <td>
                      Broken or Bent Prong
                      <input
                        value="Broken or Bent Prong"
                        v-model="qcResponses.rejections"
                        type="checkbox"
                      />
                    </td>
                    <td>
                      Broken or Fractured Metal
                      <input
                        value="Broken or Fractured Metal"
                        v-model="qcResponses.rejections"
                        type="checkbox"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Broken or Wavy Post
                      <input
                        value="Broken or Wavy Post"
                        v-model="qcResponses.rejections"
                        type="checkbox"
                      />
                    </td>
                    <td>
                      Casting Cavity
                      <input
                        value="Casting Cavity"
                        v-model="qcResponses.rejections"
                        type="checkbox"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Chain Broken/Damaged
                      <input
                        value="Chain Broken/Damaged"
                        v-model="qcResponses.rejections"
                        type="checkbox"
                      />
                    </td>
                    <td>
                      Chipped Stone(s)
                      <input
                        value="Chipped Stone(s)"
                        v-model="qcResponses.rejections"
                        type="checkbox"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Clasp Not Function Properly
                      <input
                        value="Clasp Not Function Properly"
                        v-model="qcResponses.rejections"
                        type="checkbox"
                      />
                    </td>
                    <td>
                      Crooked or Titled Head<input
                        value="Crooked or Titled Head"
                        v-model="qcResponses.rejections"
                        type="checkbox"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Crooked or Titled Stone
                      <input
                        value="Crooked or Titled Stone"
                        v-model="qcResponses.rejections"
                        type="checkbox"
                      />
                    </td>
                    <td>
                      Damaged Item or Component
                      <input
                        value="Damaged Item or Component"
                        v-model="qcResponses.rejections"
                        type="checkbox"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Detached Component(s)
                      <input
                        value="Detached Component(s)"
                        v-model="qcResponses.rejections"
                        type="checkbox"
                      />
                    </td>
                    <td>
                      Enhancements/Imitations<input
                        value="Enhancements/Imitations"
                        v-model="qcResponses.rejections"
                        type="checkbox"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Eye-visible Inclusion
                      <input
                        value="Eye-visible Inclusion"
                        v-model="qcResponses.rejections"
                        type="checkbox"
                      />
                    </td>
                    <td>
                      Eye-visible Pits/Porosity/Pin Holes
                      <input
                        value="Eye-visible Pits/Porosity/Pin Holes"
                        v-model="qcResponses.rejections"
                        type="checkbox"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Eye-visible Scratches
                      <input
                        value="Eye-visible Scratches"
                        v-model="qcResponses.rejections"
                        type="checkbox"
                      />
                    </td>
                    <td>
                      Eye-visible Toolmarks/gouges
                      <input
                        value="Eye-visible Toolmarks/gouges"
                        v-model="qcResponses.rejections"
                        type="checkbox"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Frayed or Missing Knots
                      <input
                        value="Frayed or Missing Knots"
                        v-model="qcResponses.rejections"
                        type="checkbox"
                      />
                    </td>
                    <td>
                      Improper millimeter size
                      <input
                        value="Improper millimeter size"
                        v-model="qcResponses.rejections"
                        type="checkbox"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Item Differs From Sample
                      <input
                        value="Item Differs From Sample"
                        v-model="qcResponses.rejections"
                        type="checkbox"
                      />
                    </td>
                    <td>
                      Jumpring Not Well Soldered
                      <input
                        value="Jumpring Not Well Soldered"
                        v-model="qcResponses.rejections"
                        type="checkbox"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Loose or Tight Earnut(s)
                      <input
                        value="Loose or Tight Earnut(s)"
                        v-model="qcResponses.rejections"
                        type="checkbox"
                      />
                    </td>
                    <td>
                      Loose Stones<input
                        value="Loose Stones"
                        v-model="qcResponses.rejections"
                        type="checkbox"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Low Carat Weight
                      <input
                        value="Low Carat Weight"
                        v-model="qcResponses.rejections"
                        type="checkbox"
                      />
                    </td>
                    <td>
                      Low Clarity
                      <input
                        value="Low Clarity"
                        v-model="qcResponses.rejections"
                        type="checkbox"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Low Color
                      <input
                        value="Low Color"
                        v-model="qcResponses.rejections"
                        type="checkbox"
                      />
                    </td>
                    <td>
                      Low Cut Grade
                      <input
                        value="Low Cut Grade"
                        v-model="qcResponses.rejections"
                        type="checkbox"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Low Luster
                      <input
                        value="Low Luster"
                        v-model="qcResponses.rejections"
                        type="checkbox"
                      />
                    </td>
                    <td>
                      Material Failure
                      <input
                        value="Material Failure"
                        v-model="qcResponses.rejections"
                        type="checkbox"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Metal Composition
                      <input
                        value="Metal Composition"
                        v-model="qcResponses.rejections"
                        type="checkbox"
                      />
                    </td>
                    <td>
                      Micro/Structural Porosity
                      <input
                        value="Micro/Structural Porosity"
                        v-model="qcResponses.rejections"
                        type="checkbox"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Inclusion/Structural Integrity
                      <input
                        value="Inclusion/Structural Integrity"
                        v-model="qcResponses.rejections"
                        type="checkbox"
                      />
                    </td>
                    <td>
                      Misaligned/Askew
                      <input
                        value="Misaligned/Askew"
                        v-model="qcResponses.rejections"
                        type="checkbox"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Mismatched Stones or Pearls
                      <input
                        value="Mismatched Stones or Pearls"
                        v-model="qcResponses.rejections"
                        type="checkbox"
                      />
                    </td>
                    <td>
                      Missing Earring Backs
                      <input
                        value="Missing Earring Backs"
                        v-model="qcResponses.rejections"
                        type="checkbox"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Missing Stone
                      <input
                        value="Missing Stone"
                        v-model="qcResponses.rejections"
                        type="checkbox"
                      />
                    </td>
                    <td>
                      Missing/Incorrect Stamp
                      <input
                        value="Missing/Incorrect Stamp"
                        v-model="qcResponses.rejections"
                        type="checkbox"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Pearl came unglued from peg<input
                        value="Pearl came unglued from peg"
                        v-model="qcResponses.rejections"
                        type="checkbox"
                      />
                    </td>
                    <td>
                      Pearls not strung to standards
                      <input
                        value="Pearls not strung to standards"
                        v-model="qcResponses.rejections"
                        type="checkbox"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Excess Metal
                      <input
                        value="Excess Metal"
                        v-model="qcResponses.rejections"
                        type="checkbox"
                      />
                    </td>
                    <td>
                      Poor Polish and Finish
                      <input
                        value="Poor Polish and Finish"
                        v-model="qcResponses.rejections"
                        type="checkbox"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Rough Area
                      <input
                        value="Rough Area"
                        v-model="qcResponses.rejections"
                        type="checkbox"
                      />
                    </td>
                    <td>
                      Bent Post
                      <input
                        value="Bent Post"
                        v-model="qcResponses.rejections"
                        type="checkbox"
                      />
                    </td>
                  </tr>
                </table>
              </md-card-content>
            </md-card>
          </div>
        </template>
      </Modal>

      <!-- Modal for Auth   -->

      <Modal
        @save="handleAuthChanges"
        @close="closeAuthModal"
        :showSaveBtn="Boolean(allItemsForAuthentication.length)"
        :showBackBtn="false"
        :showDialog="showAuthModal"
      >
        <template v-slot:modalContent>
          <md-card style="width: 800px">
            <md-card-content>
              <div class="filters">
                <md-field class="column">
                  <label>Search By Item/Order Number</label>
                  <md-input
                    @keydown="
                      (e) => {
                        if (e.keyCode == 13) handleAuthSearch();
                      }
                    "
                    v-model="searchAuthItems"
                  ></md-input>
                </md-field>

                <md-button class="qc-button" @click="handleAuthSearch"
                  >Find</md-button
                >
              </div>
            </md-card-content>
            <md-divider></md-divider>
            <md-card-content>
              <table class="table-wide">
                <tr class="qc-title">
                  <th>Order #</th>
                  <th>Line #</th>
                  <th>Cert #</th>
                  <th>SKU</th>
                  <th>Type</th>

                  <th>
                    <input
                      value="Passed"
                      v-model="statusForAllItems"
                      type="radio"
                    />Pass
                  </th>

                  <th>
                    <input
                      value="Failed"
                      v-model="statusForAllItems"
                      type="radio"
                    />Failed
                  </th>

                  <th>
                    <input
                      value="Skipped"
                      v-model="statusForAllItems"
                      type="radio"
                    />Skip
                  </th>
                </tr>
                <tr v-for="item in allItemsForAuthentication" :key="item.id">
                  <td class="table-center">{{ item.OrderNum }}</td>
                  <td class="table-center">{{ item.LineNum }}</td>
                  <td class="table-center">
                    {{ item.CertNum || item.ItemNum }}
                  </td>
                  <td class="table-center">{{ item.SKU || item.ClientSKU }}</td>
                  <td class="table-center">{{ item.Type }}</td>
                  <td class="table-center">
                    <input value="Passed" v-model="item.Status" type="radio" />
                  </td>
                  <td class="table-center">
                    <input value="Failed" v-model="item.Status" type="radio" />
                  </td>
                  <td class="table-center">
                    <input value="Skipped" v-model="item.Status" type="radio" />
                  </td>
                </tr>
              </table>
            </md-card-content>
          </md-card>
        </template>
      </Modal>

      <Modal
        @save="saveAuthChanges"
        :showSaveBtn="true"
        :showBackBtn="false"
        :showDialog="showQAAssociateModal"
      >
        <template v-slot:modalContent>
          <md-card>
            <md-card-content>
              <md-card>
                <md-card-content>
                  <!-- <div class="md-title">Notes:</div> -->
                  <p>Select QA Associate</p>
                  <v-select
                    :appendToBody="true"
                    :deselectFromDropdown="true"
                    v-model="selectedQaAssociate"
                    :options="allQaAssociates"
                    :clearable="false"
                    :closeOnSelect="true"
                  >
                  </v-select>
                </md-card-content>
              </md-card>
            </md-card-content>
          </md-card>
        </template>
      </Modal>
      <!-- Modal for Displaying Items Reports   -->

      <Modal
        @close="closeReportModal"
        :showDialog="showOrderReportModal"
        :showBackBtn="false"
      >
        <template v-slot:modalContent>
          <md-card>
            <md-card-content class="row">
              <md-field>
                <label>Customer</label>
                <md-input disabled :value="selectedOrder.Customer"></md-input>
              </md-field>
              <md-field>
                <label>Order #</label>
                <md-input disabled :value="selectedOrder.OrderNum"></md-input>
              </md-field>
              <md-field>
                <label>Date/Time</label>
                <md-input
                  disabled
                  :value="selectedOrder.Timestamp | format_date_time"
                ></md-input>
              </md-field>
              <md-field>
                <label>QTY</label>
                <md-input disabled :value="selectedOrder.Quantity"></md-input>
              </md-field>
            </md-card-content>
            <md-divider></md-divider>
            <md-card-content>
              <table class="table-wide">
                <tr class="qc-title">
                  <th>Line #</th>
                  <th>Cert #</th>
                  <th>SKU</th>
                  <th>Type</th>
                  <th>Status</th>
                  <th>Notes</th>
                  <th>Results</th>
                  <th>Images</th>
                </tr>
                <tr
                  style="text-align: center"
                  v-for="(item, k) in allOrderItemsInfo"
                  :key="k"
                >
                  <td>{{ item.LineNum }}</td>
                  <td>{{ item.CertNum || item.ItemNum }}</td>
                  <td>{{ item.SKU }}</td>
                  <td>{{ item.Type }}</td>
                  <td>{{ item.Status }}</td>
                  <td style="width: 100px">
                    <div class="notes">
                      <span>{{ item.Responses.notes }} </span>
                    </div>
                    <button
                      v-if="activeUser.isAdmin || activeUser.isSuperAdmin"
                      @click="handleNoteModal(item, k)"
                      class="btn all-text-btn"
                    >
                      Add Notes
                    </button>
                  </td>
                  <td style="width: 100px">
                    <div class="results">
                      <p
                        v-for="(rejection, i) in item.Responses.rejections"
                        :key="i"
                      >
                        {{ rejection }}
                      </p>
                    </div>
                  </td>
                  <td>
                    <div style="display: flex; flex-direction: column">
                      <button
                        v-if="item.Images.length"
                        @click="
                          (showItemImagesModal = true),
                            (selectedFailedItem = item),
                            (selectedItemType = 'failed')
                        "
                        class="btn all-text-btn"
                      >
                        View
                      </button>
                      <button
                        v-else
                        v-show="activeUser.isAdmin || activeUser.isSuperAdmin"
                        @click="
                          (showItemImagesModal = true),
                            (selectedFailedItem = item),
                            (selectedItemType = 'failed')
                        "
                        class="btn all-text-btn"
                      >
                        Upload
                      </button>
                    </div>
                  </td>
                </tr>
              </table>
            </md-card-content>
          </md-card>
        </template>
      </Modal>

      <!-- Modal for Adding Notes on Report Modal -->
      <Modal
        :showDialog="showNoteModal"
        :showBackBtn="false"
        :showSaveBtn="true"
        @close="closeNoteModal"
        @save="handleSaveNote"
      >
        <template v-slot:modalContent>
          <div
            class="notes-container"
            style="display: flex; flex-direction: column"
          >
            <h3>Notes:</h3>
            <md-field>
              <md-textarea
                v-if="selectedItem.Responses"
                v-model="selectedItem.Responses.notes"
              ></md-textarea>
            </md-field>
          </div>
        </template>
      </Modal>

      <!-- Modal for Displaying Failed Item Images  -->
      <Modal
        @close="closeImageModal"
        :showDialog="showItemImagesModal"
        :showBackBtn="false"
      >
        <template v-slot:modalContent>
          <md-card>
            <md-card-content>
              <MultImages
                @removeImage="removeImage"
                @uploadedImages="uploadedImages"
                :media="selectedFailedItem.Images"
                :item="selectedFailedItem"
              />
            </md-card-content>
          </md-card>
        </template>
      </Modal>

      <!-- Modal for Q/A Multiple Items   -->

      <Modal
        @close="showQCModalForMult = false"
        :showSaveBtn="false"
        :showDialog="showQCModalForMult"
        :showBackBtn="false"
      >
        <template v-slot:modalContent>
          <div class="toggle_tabs">
            <div
              class="tab"
              v-for="(item, t_index) in allFailedItems"
              :key="t_index"
              :class="{ active: popupTab == t_index }"
            >
              <p>{{ item.CertNum || item.ItemNum }}</p>
            </div>
          </div>
          <div
            v-for="(item, index) in allFailedItems"
            :key="index"
            class="inputs"
          >
            <transition name="fade">
              <div v-if="popupTab == index">
                <md-card>
                  <md-card-content>
                    <div class="md-title">Notes:</div>
                    <md-field>
                      <md-textarea
                        v-model="item.qcResponses.notes"
                      ></md-textarea>
                    </md-field>
                  </md-card-content>
                </md-card>
                <div class="container qc">
                  <md-card class="left">
                    <md-card-content>
                      <table class="qc">
                        <tr class="qc-title">
                          <th class="qc">Overall</th>
                        </tr>
                        <tr>
                          <td>
                            <input
                              v-model="item.qcResponses.overall.a1.value"
                              type="checkbox"
                            />
                          </td>
                          <td>
                            Product Status - if listed as Pre-Launch, do not QC
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <input
                              v-model="item.qcResponses.overall.a2.value"
                              type="checkbox"
                            />
                          </td>
                          <td>Product matches photo</td>
                        </tr>
                        <tr>
                          <td>
                            <input
                              v-model="qcResponses.overall.a3.value"
                              @input="leftQCPanelInit = true"
                              type="checkbox"
                            />
                          </td>
                          <td>Other</td>
                        </tr>
                        <tr class="qc-title">
                          <th class="qc">Stones</th>
                          <th></th>
                          <th></th>
                        </tr>
                        <tr>
                          <td>
                            <input
                              v-model="item.qcResponses.stones.a1.value"
                              type="checkbox"
                            />
                          </td>
                          <td>Stone count</td>
                        </tr>
                        <tr>
                          <td>
                            <input
                              v-model="item.qcResponses.stones.a2.value"
                              type="checkbox"
                            />
                          </td>
                          <td>Stone size</td>
                        </tr>
                        <tr>
                          <td>
                            <input
                              v-model="item.qcResponses.stones.a3.value"
                              type="checkbox"
                            />
                          </td>
                          <td>Stone clarity</td>
                        </tr>
                        <tr>
                          <td>
                            <input
                              v-model="item.qcResponses.stones.a4.value"
                              type="checkbox"
                            />
                          </td>
                          <td>Stone color</td>
                        </tr>
                        <tr class="qc-title">
                          <th class="qc">Rings</th>
                        </tr>
                        <tr>
                          <td>
                            <input
                              v-model="item.qcResponses.rings.a1.value"
                              type="checkbox"
                            />
                          </td>
                          <td>Size</td>
                        </tr>
                        <tr>
                          <td>
                            <input
                              v-model="item.qcResponses.rings.a2.value"
                              type="checkbox"
                            />
                          </td>
                          <td>Shank height/width</td>
                        </tr>
                        <tr>
                          <td>
                            <input
                              v-model="item.qcResponses.rings.a3.value"
                              type="checkbox"
                            />
                          </td>
                          <td>Rhodium + color (if applicable)</td>
                        </tr>
                        <tr>
                          <td>
                            <input
                              v-model="item.qcResponses.rings.a4.value"
                              type="checkbox"
                            />
                          </td>
                          <td>Prong Count</td>
                        </tr>
                        <tr>
                          <td>
                            <input
                              v-model="item.qcResponses.rings.a5.value"
                              type="checkbox"
                            />
                          </td>
                          <td>Metal Type</td>
                        </tr>
                        <tr class="qc-title">
                          <th class="qc">Earrings</th>
                        </tr>
                        <tr>
                          <td>
                            <input
                              v-model="item.qcResponses.earrings.a1.value"
                              type="checkbox"
                            />
                          </td>
                          <td>Length/Width</td>
                        </tr>
                        <tr>
                          <td>
                            <input
                              v-model="item.qcResponses.earrings.a2.value"
                              type="checkbox"
                            />
                          </td>
                          <td>Rhodium + color (if applicable)</td>
                        </tr>
                        <tr>
                          <td>
                            <input
                              v-model="item.qcResponses.earrings.a3.value"
                              type="checkbox"
                            />
                          </td>
                          <td>Backing type</td>
                        </tr>
                      </table>
                    </md-card-content>
                  </md-card>
                  <md-card class="right">
                    <md-card-content>
                      <table class="qc-2">
                        <tr class="qc-title">
                          <th class="qc-2-title">Rejection Reasons</th>
                        </tr>
                        <tr>
                          <td>
                            Broken or Bent Prong
                            <input
                              value="Broken or Bent Prong"
                              v-model="item.qcResponses.rejections"
                              type="checkbox"
                            />
                          </td>
                          <td>
                            Broken or Fractured Metal
                            <input
                              value="Broken or Fractured Metal"
                              v-model="item.qcResponses.rejections"
                              type="checkbox"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Broken or Wavy Post
                            <input
                              value="Broken or Wavy Post"
                              v-model="item.qcResponses.rejections"
                              type="checkbox"
                            />
                          </td>
                          <td>
                            Casting Cavity
                            <input
                              value="Casting Cavity"
                              v-model="item.qcResponses.rejections"
                              type="checkbox"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Chain Broken/Damaged
                            <input
                              value="Chain Broken/Damaged"
                              v-model="item.qcResponses.rejections"
                              type="checkbox"
                            />
                          </td>
                          <td>
                            Chipped Stone(s)
                            <input
                              value="Chipped Stone(s)"
                              v-model="item.qcResponses.rejections"
                              type="checkbox"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Clasp Not Function Properly
                            <input
                              value="Clasp Not Function Properly"
                              v-model="item.qcResponses.rejections"
                              type="checkbox"
                            />
                          </td>
                          <td>
                            Crooked or Titled Head<input
                              value="Crooked or Titled Head"
                              v-model="item.qcResponses.rejections"
                              type="checkbox"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Crooked or Titled Stone
                            <input
                              value="Crooked or Titled Stone"
                              v-model="item.qcResponses.rejections"
                              type="checkbox"
                            />
                          </td>
                          <td>
                            Damaged Item or Component
                            <input
                              value="Damaged Item or Component"
                              v-model="item.qcResponses.rejections"
                              type="checkbox"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Detached Component(s)
                            <input
                              value="Detached Component(s)"
                              v-model="item.qcResponses.rejections"
                              type="checkbox"
                            />
                          </td>
                          <td>
                            Enhancements/Imitations<input
                              value="Enhancements/Imitations"
                              v-model="item.qcResponses.rejections"
                              type="checkbox"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Eye-visible Inclusion
                            <input
                              value="Eye-visible Inclusion"
                              v-model="item.qcResponses.rejections"
                              type="checkbox"
                            />
                          </td>
                          <td>
                            Eye-visible Pits/Porosity/Pin Holes
                            <input
                              value="Eye-visible Pits/Porosity/Pin Holes"
                              v-model="item.qcResponses.rejections"
                              type="checkbox"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Eye-visible Scratches
                            <input
                              value="Eye-visible Scratches"
                              v-model="item.qcResponses.rejections"
                              type="checkbox"
                            />
                          </td>
                          <td>
                            Eye-visible Toolmarks/gouges
                            <input
                              value="Eye-visible Toolmarks/gouges"
                              v-model="item.qcResponses.rejections"
                              type="checkbox"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Frayed or Missing Knots
                            <input
                              value="Frayed or Missing Knots"
                              v-model="item.qcResponses.rejections"
                              type="checkbox"
                            />
                          </td>
                          <td>
                            Improper millimeter size
                            <input
                              value="Improper millimeter size"
                              v-model="item.qcResponses.rejections"
                              type="checkbox"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Item Differs From Sample
                            <input
                              value="Item Differs From Sample"
                              v-model="item.qcResponses.rejections"
                              type="checkbox"
                            />
                          </td>
                          <td>
                            Jumpring Not Well Soldered
                            <input
                              value="Jumpring Not Well Soldered"
                              v-model="item.qcResponses.rejections"
                              type="checkbox"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Loose or Tight Earnut(s)
                            <input
                              value="Loose or Tight Earnut(s)"
                              v-model="item.qcResponses.rejections"
                              type="checkbox"
                            />
                          </td>
                          <td>
                            Loose Stones<input
                              value="Loose Stones"
                              v-model="item.qcResponses.rejections"
                              type="checkbox"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Low Carat Weight
                            <input
                              value="Low Carat Weight"
                              v-model="item.qcResponses.rejections"
                              type="checkbox"
                            />
                          </td>
                          <td>
                            Low Clarity
                            <input
                              value="Low Clarity"
                              v-model="item.qcResponses.rejections"
                              type="checkbox"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Low Color
                            <input
                              value="Low Color"
                              v-model="item.qcResponses.rejections"
                              type="checkbox"
                            />
                          </td>
                          <td>
                            Low Cut Grade
                            <input
                              value="Low Cut Grade"
                              v-model="item.qcResponses.rejections"
                              type="checkbox"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Low Luster
                            <input
                              value="Low Luster"
                              v-model="item.qcResponses.rejections"
                              type="checkbox"
                            />
                          </td>
                          <td>
                            Material Failure
                            <input
                              value="Material Failure"
                              v-model="item.qcResponses.rejections"
                              type="checkbox"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Metal Composition
                            <input
                              value="Metal Composition"
                              v-model="item.qcResponses.rejections"
                              type="checkbox"
                            />
                          </td>
                          <td>
                            Micro/Structural Porosity
                            <input
                              value="Micro/Structural Porosity"
                              v-model="item.qcResponses.rejections"
                              type="checkbox"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Inclusion/Structural Integrity
                            <input
                              value="Inclusion/Structural Integrity"
                              v-model="item.qcResponses.rejections"
                              type="checkbox"
                            />
                          </td>
                          <td>
                            Misaligned/Askew
                            <input
                              value="Misaligned/Askew"
                              v-model="item.qcResponses.rejections"
                              type="checkbox"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Mismatched Stones or Pearls
                            <input
                              value="Mismatched Stones or Pearls"
                              v-model="item.qcResponses.rejections"
                              type="checkbox"
                            />
                          </td>
                          <td>
                            Missing Earring Backs
                            <input
                              value="Missing Earring Backs"
                              v-model="item.qcResponses.rejections"
                              type="checkbox"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Missing Stone
                            <input
                              value="Missing Stone"
                              v-model="item.qcResponses.rejections"
                              type="checkbox"
                            />
                          </td>
                          <td>
                            Missing/Incorrect Stamp
                            <input
                              value="Missing/Incorrect Stamp"
                              v-model="item.qcResponses.rejections"
                              type="checkbox"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Pearl came unglued from peg<input
                              value="Pearl came unglued from peg"
                              v-model="item.qcResponses.rejections"
                              type="checkbox"
                            />
                          </td>
                          <td>
                            Pearls not strung to standards
                            <input
                              value="Pearls not strung to standards"
                              v-model="item.qcResponses.rejections"
                              type="checkbox"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Excess Metal
                            <input
                              value="Excess Metal"
                              v-model="item.qcResponses.rejections"
                              type="checkbox"
                            />
                          </td>
                          <td>
                            Poor Polish and Finish
                            <input
                              value="Poor Polish and Finish"
                              v-model="item.qcResponses.rejections"
                              type="checkbox"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Rough Area
                            <input
                              value="Rough Area"
                              v-model="item.qcResponses.rejections"
                              type="checkbox"
                            />
                          </td>
                          <td>
                            Bent Post
                            <input
                              value="Bent Post"
                              v-model="item.qcResponses.rejections"
                              type="checkbox"
                            />
                          </td>
                        </tr>
                      </table>
                    </md-card-content>
                  </md-card>
                </div>
              </div>
            </transition>
            <!-- <md-button v-if="index" @click="removeInstall">Remove</md-button> -->
          </div>
          <div>
            <!-- <md-button @click="save">Next</md-button> -->
            <!-- <md-button @click="closeInstallationPopup">Close</md-button> -->
          </div>
        </template>
        <template v-slot:dialog-actions>
          <md-button
            v-if="popupTab !== allFailedItems.length - 1"
            class="md-primary"
            @click="saveQC"
            >Next</md-button
          >
          <md-button
            v-if="popupTab === allFailedItems.length - 1"
            class="md-primary"
            @click="finishQCProtocol"
            >Finish</md-button
          >
        </template>
      </Modal>

      <!-- Modal For Displaying Who Authenticated -->

      <Modal
        @close="closeAuthViewModal"
        :showDialog="showAuthViewModal"
        :showBackBtn="false"
      >
        <template v-slot:modalContent>
          <md-card>
            <md-card-content class="row">
              <md-field>
                <label>Customer</label>
                <md-input disabled :value="selectedOrder.Customer"></md-input>
              </md-field>
              <md-field>
                <label>Order #</label>
                <md-input disabled :value="selectedOrder.OrderNum"></md-input>
              </md-field>
              <md-field>
                <label>Date/Time</label>
                <md-input
                  disabled
                  :value="selectedOrder.Timestamp | format_date_time"
                ></md-input>
              </md-field>
              <md-field>
                <label>QTY</label>
                <md-input disabled :value="selectedOrder.Quantity"></md-input>
              </md-field>
            </md-card-content>
            <md-divider></md-divider>
            <md-card-content>
              <table class="table-wide">
                <tr class="qc-title">
                  <th>Line #</th>
                  <th>Cert #</th>
                  <th>SKU</th>
                  <th>Type</th>
                  <th>Status</th>
                  <th>Authenticated By</th>
                </tr>
                <tr
                  style="text-align: center"
                  v-for="(item, k) in allLineItems"
                  :key="k"
                >
                  <td>{{ item.LineNum }}</td>
                  <td>{{ item.CertNum || item.ItemNum }}</td>
                  <td>{{ item.SKU || item.ClientSKU }}</td>
                  <td>{{ item.Type }}</td>
                  <td>{{ item.Status }}</td>
                  <td v-if="item.QA_Associate">{{ item.QA_Associate }}</td>
                  <td v-else-if="selectedOrder.QA_Associate">
                    {{ selectedOrder.QA_Associate }}
                  </td>
                  <td v-else>Not Authenticated</td>
                </tr>
              </table>
            </md-card-content>
          </md-card>
        </template>
      </Modal>

      <!-- Modal for Exports Options 
      <Modal
        @close="(showExportOptions = false), (selectedExportData = [])"
        :showDialog="showExportOptions"
        :showBackBtn="false"
      >
        <template v-slot:modalContent>
          <md-card>
            <md-card-content>
              <md-button class="md-primary">Export All Table Data</md-button>
              <md-button class="md-primary">Export Selected Items</md-button>
              <md-button class="md-primary"
                >Export Only Completed Items</md-button
              >
            </md-card-content>
          </md-card>
        </template>
      </Modal> -->
    </div>

    <md-snackbar
      md-position="center"
      :md-duration="5000"
      :md-active.sync="showSnackbar"
      md-persistent
    >
      <span>{{ snackbarMsg }}</span>
    </md-snackbar>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import moment from "moment";
import Pagination from "@/components/Pagination.vue";
import TableModal from "@/components/TableModal.vue";
import Modal from "@/components/Modal.vue";
import Table from "@/components/Table.vue";
import SelectableTable from "@/components/SelectableTable.vue";
import MultImages from "@/components/Inputs/UploadMultiple.vue";
import { db, functions } from "@/config/firebaseInit.js";
import firebase from "firebase";

const { DateTime } = require("luxon");

export default {
  name: "Home",
  components: {
    Pagination,
    TableModal,
    Modal,
    Table,
    MultImages,
    SelectableTable,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data.
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData.slice(this.from, this.to);
      }
      return result;
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableSize;
    },
  },

  data() {
    return {
      activeUser: {},
      selectedItemType: "",
      currentSort: "name",
      currentSortOrder: "asc",
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [50, 250, 500, 1000],
        total: 0,
      },
      searchQuery: "",
      searchVal: "",
      selectedSearchBy: "on",
      allItemsForAuthentication: [],
      filterBy: {
        showFailedItems: false,
        showPassedItems: false,
        showPassedWithRepair: false,
        client: "",
        status: "",
        customers: "",
        startDate: "",
        endDate: "",
      },
      tableSize: 0,
      propsToSearch: ["title", "fullname", "description"],
      showModalContentForLineItem: false,
      showQAAssociateModal: false,
      showModalContentForItem: false,
      selectedQaAssociate: "",
      allQaAssociates: [],
      showImageModal: false,
      showSelectableTable: false,
      showSnackbar: false,
      statusForAllItems: null,
      showChangesBtn: false,
      allFailedItems: [],
      allItemsForSelectedLine: [],
      selectedLineItem: {},
      selectedItem: {},
      selectedFailedItem: {},
      showModalContent: false,
      showExportOptions: false,
      selectedExportData: [],
      searchAuthItems: null,
      popupTab: null,
      lineItemsInfo: [
        // {
        //   label: "Customer",
        //   key: "Customer",
        // },
        // {
        //   label: "Order #",
        //   key: "OrderNum",
        // },
        {
          label: "Line #",
          key: "LineNum",
        },
        {
          label: "Qty",
          key: "Quantity",
        },
      ],
      itemsInfo: [
        {
          label: "Customer",
          key: "Customer",
        },
        {
          label: "Order #",
          key: "OrderNum",
        },
        {
          label: "Line #",
          key: "LineNum",
        },
        {
          label: "Cert #",
          key: "CertNum",
        },

        {
          label: "Status",
          key: "Status",
        },
      ],
      itemsInfoForAuth: [
        {
          label: "Cert #",
          key: "CertNum",
        },
        {
          label: "Type",
          key: "Type",
        },
        {
          label: "Description",
          key: "Description",
        },
      ],
      exportItemsInfo: [
        {
          label: "Order #",
          key: "OrderNum",
        },
        //  {
        //   label: "Date/Time",
        //   key: "Timestamp",
        // },
        {
          label: "Client",
          key: "Client",
        },
        {
          label: "Customer",
          key: "Customer",
        },
        {
          label: "Quantity",
          key: "Quantity",
        },
        {
          label: "FailedItems",
          key: "FailedItems",
        },
        {
          label: "Status",
          key: "Status",
        },
      ],
      unfilteredItems: [],
      tableData: [],
      selectedOrderLineStatus: [],
      allLineItems: [],
      searchedData: [],
      selectedItems: [],
      customers: [],
      clients: [],
      showDialog: false,
      showQCModal: false,
      showQCModalForMult: false,
      showAuthModal: false,
      showOrderReportModal: false,
      showItemImagesModal: false,
      showNoteModal: false,
      allOrderItemsInfo: {},
      selectedOrder: {},
      ordersRef: {},
      leftQCPanelInit: false,
      showAuthViewModal: false,
      qcResponses: {
        overall: {
          a1: {
            question: "Product Status - if listed as Pre-Launch, do not QC",
            value: false,
          },
          a2: {
            question: "Product matches photo",
            value: false,
          },
          a3: {
            question: "Other",
            value: false,
          },
        },
        stones: {
          a1: {
            question: "Stone Count",
            value: false,
          },
          a2: {
            question: "Stone size",
            value: false,
          },
          a3: {
            question: "Stone clarity",
            value: false,
          },
          a4: {
            question: "Stone color",
            value: false,
          },
        },
        rings: {
          a1: {
            question: "Size",
            value: false,
          },
          a2: {
            question: "Shank height/width",
            value: false,
          },
          a3: {
            question: "Rhodium + color (if applicable)",
            value: false,
          },
          a4: {
            question: "Prong count",
            value: false,
          },
          a5: {
            question: "Metal type",
            value: false,
          },
        },
        earrings: {
          a1: {
            question: "Length/Width",
            value: false,
          },
          a2: {
            question: "Rhodium + color (if applicable)",
            value: false,
          },
          a3: {
            question: "Backing Type",
            value: false,
          },
        },
        rejections: [],
        notes: "",
      },
      snackbarMsg: "",
      // routeChange: true,
    };
  },

  async created() {
    this.activeUser = this.$store.state.user;

    if (this.$store.state.user.isAdmin || this.$store.state.user.isSuperAdmin) {
      if (this.$store.state.user.isSuperAdmin) this.getQAAssociate();
      else this.selectedQaAssociate = this.$store.state.user.fullname;
      this.ordersRef = db.collection("orders");
    } else if (this.$store.state.user.customerID) {
      const customerID = this.$store.state.user.customerID;
      this.ordersRef = db
        .collection("orders")
        .where("Customer", "==", customerID);
    } else if (this.$store.state.user.clientID) {
      let clientID = this.$store.state.user.clientID;
      this.ordersRef = db.collection("orders").where("Client", "==", clientID);
    }

    this.$bind("null", db.doc("analytics/orders")).then((doc) => {
      this.tableSize = doc.total;
    });

    this.fetchData("start");
    this.originPath = window.location.origin;
  },

  firestore() {
    if (this.$store.state.user.isAdmin || this.$store.state.user.isSuperAdmin) {
      return {
        customers: db.collection("customers"),
        clients: db.collection("clients"),
      };
    } else if (this.$store.state.user.clientID) {
      let clientID = this.$store.state.user.clientID;
      return {
        customers: db.collection("customers").where("clientID", "==", clientID),
      };
    }
  },

  watch: {
    statusForAllItems(val) {
      if (val) {
        this.handleStatuses(val);
      }
    },
  },

  methods: {
    resetTable() {
      this.pagination.currentPage = 1;
      this.searchedData = [];
      this.fetchData("start");
    },
    async fetchData(action) {
      if (this.searchedData.length) return false;
      if (action == "start") {
        let loader = this.$loading.show({
          container: null,
          canCancel: false,
        });
        try {
          const querySnapshot = this.ordersRef
            .orderBy("Timestamp", "desc") // Change to your sorting criteria
            .endAt(0)
            .limit(this.pagination.perPage);

          this.$bind("tableData", querySnapshot);
          loader.hide();
        } catch (error) {
          loader.hide();
          console.error("Error fetching data:", error);
        }
      } else if (action == "next") {
        this.fetchNextData();
      } else if (action == "prev") {
        this.fetchPreviousData();
      } else if (action == "per-page") {
        let firstDoc = this.tableData[0];
        firstDoc = await this.productsRef.doc(firstDoc.id).get();
        const querySnapshot = await this.productsRef
          .orderBy("Timestamp", "desc") // Change to your sorting criteria
          .startAt(firstDoc)
          .limit(this.pagination.perPage)
          .get();

        this.tableData = querySnapshot.docs.map((doc) =>
          Object.assign({ id: doc.id }, doc.data())
        );
      }
    },
    async fetchNextData() {
      let loader = this.$loading.show({
        container: null,
        canCancel: false,
      });
      let lastDoc = this.tableData[this.tableData.length - 1];
      lastDoc = await this.ordersRef.doc(lastDoc.id).get();
      this.tableData = [];
      try {
        const querySnapshot = await this.ordersRef
          // .orderBy("Timestamp")
          .orderBy("Timestamp", "desc") // Change to your sorting criteria
          .startAfter(lastDoc)
          .limit(this.pagination.perPage)
          .get();

        this.tableData = querySnapshot.docs.map((doc) =>
          Object.assign({ id: doc.id }, doc.data())
        );
        loader.hide();
      } catch (error) {
        console.error("Error fetching data:", error);
        loader.hide();
      }
    },
    async fetchPreviousData() {
      let loader = this.$loading.show({
        container: null,
        canCancel: false,
      });
      let firstDoc = this.tableData[0];
      firstDoc = await this.ordersRef.doc(firstDoc.id).get();
      this.tableData = [];
      try {
        const querySnapshot = await this.ordersRef
          // .orderBy("created")
          .orderBy("Timestamp", "asc") // Change to your sorting criteria
          .startAfter(firstDoc)
          .limit(this.pagination.perPage)
          .get();

        this.tableData = querySnapshot.docs
          .map((doc) => Object.assign({ id: doc.id }, doc.data()))
          .reverse();
        loader.hide();
      } catch (error) {
        console.error("Error fetching data:", error);
        loader.hide();
      }
    },
    async getQAAssociate() {
      let allQaAssociates = await db.doc("attributes/qa_associates").get();
      allQaAssociates = allQaAssociates.data().employees;
      this.allQaAssociates = allQaAssociates;
    },
    onSelected(data) {
      this.selectedExportData = data;
    },
    handleNoteModal(item, id) {
      this.showNoteModal = true;
      this.selectedItem = item;
      this.selectedItem.id = id;
    },
    closeNoteModal() {
      this.showNoteModal = false;
      this.selectedItem = {};
    },
    closeImageModal() {
      this.showItemImagesModal = false;
      this.selectedFailedItem = {};
      this.selectedItemType = null;
    },
    handleSaveNote() {
      this.allOrderItemsInfo[this.selectedItem.id].Responses.notes =
        this.selectedItem.Responses.notes;

      db.doc(
        `orders/${this.selectedItem.OrderNum}/quality_control/${this.selectedItem.id}`
      )
        .set({ notes: this.selectedItem.Responses.notes, rejections: [] })
        .then(async () => {
          const order = this.selectedOrder;
          this.snackbarMsg = "Note has been saved";
          this.showSnackbar = true;
          this.closeNoteModal();
          this.closeReportModal();
          this.showFailedItemReport(order);
        });
    },
    async exportCSV(action) {
      let format_date = (v) => moment(v).format("MM/DD/YYYY");
      let loader = this.$loading.show({
        container: null,
        canCancel: false,
      });
      const { selectedExportData, searchedData, tableData } = this;
      let payload =
        action === "all"
          ? searchedData.length > 0
            ? searchedData
            : tableData
          : selectedExportData;
      let allOrderItemsRejectionNotes = {};
      let data = [];
      let columns =
        "Date,Order,Venue,Purchase Orders,SKU,Certificate,Status,Rejections,Images\n";

      const promisedData = payload.map(async (o) => {
        const orderID = o.id;
        const orderTimeStamp = o.Timestamp;
        allOrderItemsRejectionNotes[orderID] = {};
        let items;
        let order = await db.doc(`orders/${orderID}`).get();
        order = order.data();
        if (this.filterBy.showFailedItems) {
          items = await db
            .collection(`orders/${orderID}/items`)
            .where("Status", "==", "Failed")
            .get();
        } else if (this.filterBy.showPassedWithRepair) {
          items = await db
            .collection(`orders/${orderID}/items`)
            .where("Status", "==", "Passed-W/Repair")
            .get();
        } else if (this.filterBy.showCertificateOrders) {
          items = await db
            .collection(`orders/${orderID}/items`)
            .where("CertNum", "!=", "")
            .get();
        } else {
          items = await db
            .collection(`orders/${orderID}/items`)
            .where("Status", "!=", "Needs Q/A")
            .get();
        }

        let itemsQAReportDocs = await db
          .collection(`orders/${orderID}/quality_control`)
          .get();

        itemsQAReportDocs.docs.forEach((d) => {
          console.log("itemsQAReportDocs", d.id);
          const qaInfo = d.data();
          allOrderItemsRejectionNotes[orderID][d.id] = qaInfo.rejections.length
            ? qaInfo.rejections.join(" ")
            : qaInfo.notes
            ? qaInfo.notes.replace(/[\r\n]/gm, "")
            : "";
        });
        items = items.docs
          .map((d) => {
            const item = Object.assign({ id: d.id }, d.data());
            item.Timestamp = orderTimeStamp;
            item.PO = item.PO || order.PurchaseOrders[0];
            item.Customer = item.Customer.replace(",", ".");
            item.ImageURL = item.Images.length
              ? item.Images.map(
                  (img) =>
                    `"=HYPERLINK(""${img.url}"", ""${
                      item.CertNum || item.ItemNum
                    }"")"`
                )[0]
              : "";
            if (allOrderItemsRejectionNotes[orderID][item.id]) {
              item.qaNote = allOrderItemsRejectionNotes[orderID][item.id];
            } else {
              item.qaNote = "";
            }

            return item;
          })
          .sort((a, b) => a.LineNum - b.LineNum);
        data.push(items);
        return data;
      });
      await Promise.all(promisedData);
      data = [].concat(...data).sort((a, b) => a.Timestamp - b.Timestamp);
      let rows = data
        .map(
          (d) =>
            `${format_date(d.Timestamp)},${d.OrderNum}-${d.LineNum},${
              d.Customer
            },${d.PO},${d.SKU || d.ClientSKU},${d.CertNum || d.ItemNum},${
              d.Status
            },${d.qaNote},${d.ImageURL}`
        )
        .join("\r\n");

      let csvContent = "data:text/csv;charset=utf-8," + columns + rows;
      let encodedUri = encodeURI(csvContent);
      let link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `QA Report ${moment().format("LL")}.csv`);
      document.body.appendChild(link); // Required for FF
      link.click();

      this.showExportOptions = false;
      this.showSelectableTable = false;
      this.selectedExportData = [];
      loader.hide();
    },
    removeImage(data) {
      this.selectedFailedItem.Images = this.selectedFailedItem.Images.filter(
        (img) => img.name != data.name
      );
      this.$forceUpdate();
      db.doc(
        `orders/${this.selectedFailedItem.OrderNum}/items/${this.selectedFailedItem.ItemNum}`
      ).update({
        Images: this.selectedFailedItem.Images,
      });
    },
    uploadedImages(data) {
      if (this.selectedItemType === "failed") {
        return this.uploadImageForFailedItem(data);
      } else if (this.selectedItemType == "individual") {
        return this.uploadImageForIndividualItem(data);
      }
    },
    uploadImageForFailedItem(data) {
      let additionalData = {};
      if (this.selectedFailedItem.Status == "Passed") {
        additionalData = {
          Status: "Passed-W/Repair",
          QCCompleted: true,
        };
      }

      db.doc(
        `orders/${this.selectedFailedItem.OrderNum}/items/${this.selectedFailedItem.ItemNum}`
      )
        .update(
          Object.assign(
            {
              Images: firebase.firestore.FieldValue.arrayUnion(data),
            },
            additionalData
          )
        )
        .then(() => {
          const order = this.selectedOrder;
          this.snackbarMsg = "Image Uploaded Successfully";
          this.showSnackbar = true;
          this.closeImageModal();
          this.closeReportModal();
          this.showFailedItemReport(order);
        })
        .catch((err) => {
          this.snackbarMsg = `Error has occurred ${err.message}. Try again later or contact support`;
          this.showSnackbar = true;
        });

      this.selectedFailedItem.Images.push(data);
    },
    uploadImageForIndividualItem(data) {
      db.doc(
        `orders/${this.selectedItem.OrderNum}/items/${this.selectedItem.ItemNum}`
      )
        .update({ ImageURL: data.url })
        .then(() => {
          this.selectedItem.ImageURL = data.url;
          this.snackbarMsg = "Image Uploaded Successfully";
          this.showSnackbar = true;
          this.closeImageModal();
        })
        .catch((err) => {
          this.snackbarMsg = `Error has occurred ${err.message}. Try again later or contact support`;
          this.showSnackbar = true;
        });
    },
    saveDescription(item) {
      return db
        .doc(`orders/${this.selectedOrder.OrderNum}/line_items/${item.LineNum}`)
        .update({ Description: item.Description })
        .then(() => {
          this.showChangesBtn = false;
          this.snackbarMsg = "Changes has been saved successfully";
          this.showSnackbar = true;
        })
        .catch((err) => {
          this.snackbarMsg = `Error has occurred ${err.message}. Try again later or contact support`;
          this.showSnackbar = true;
        });
    },
    goBack(cur, prev) {
      this[cur] = false;
      this[prev] = true;
    },
    handleImageModal(url) {
      this.imageView = url;
      this.showImageModal = true;
    },
    handleSearchForIndividualItem(e) {
      const value = e;
      if (!value) return (this.tableData = this.unfilteredItems);
      this.allLineItems = this.allLineItemsUnfiltered.filter((i) =>
        i.ItemNum.includes(value)
      );
    },
    saveQC() {
      let item = this.allFailedItems[this.popupTab];
      item.qcResponses.createdBy = this.activeUser.uid;
      return db
        .doc(`orders/${item.OrderNum}/quality_control/${item.ItemNum}`)
        .set(item.qcResponses, { merge: true })
        .then(() => this.changeItemTab(this.popupTab + 1));
    },
    finishQCProtocol() {
      this.saveQC().then(() => {
        this.popupTab = null;
        this.showQCModalForMult = false;

        if (this.$store.state.user.isSuperAdmin) {
          this.showQAAssociateModal = true;
        } else {
          this.saveAuthChanges();
        }
      });
    },
    changeItemTab(tab) {
      this.popupTab = null;
      setTimeout(() => {
        this.popupTab = tab;
      }, 500);
      return true;
    },
    closeReportModal() {
      this.selectedOrder = {};
      this.allOrderItemsInfo = {};
      this.showOrderReportModal = false;
    },
    async showFailedItemReport(item) {
      let loader = this.$loading.show({
        container: null,
        canCancel: false,
      });

      this.selectedOrder = item;

      let allOrderItems = await db
        .collection(`orders/${item.OrderNum}/items`)
        .get();
      let responses = await db
        .collection(`orders/${item.OrderNum}/quality_control`)
        .get();

      for (let index = 0; index < allOrderItems.docs.length; index++) {
        const d = allOrderItems.docs[index];
        this.allOrderItemsInfo[d.id] = d.data();
        this.allOrderItemsInfo[d.id]["Responses"] = {
          notes: "",
          rejections: [],
        };
      }

      responses.docs.forEach((d) => {
        let response = d.data();
        this.allOrderItemsInfo[d.id]["Responses"] = response;
      });

      this.showOrderReportModal = true;
      loader.hide();
    },
    closeAuthModal() {
      this.allItemsForAuthentication = [];
      this.showAuthModal = false;
      this.searchAuthItems = null;
      this.showQAAssociateModal = false;
    },
    endAuthSequence() {
      this.allItemsForAuthentication = [];
      this.searchAuthItems = null;
      this.showAuthModal = false;
      this.showQAAssociateModal = false;
      if (this.$store.state.user.isSuperAdmin) this.selectedQaAssociate = null;
    },
    handleStatuses(status) {
      this.allItemsForAuthentication.forEach((i) => {
        if (!i.QA_Associate) i.Status = status;
      });
    },
    handleAuthChanges() {
      let allItemsForAuthentication = this.allItemsForAuthentication;
      let allFailedItemsThatWereNotQA = allItemsForAuthentication.filter(
        (i) => i.Status === "Failed" && !i.QCCompleted
      );

      let allFailedItemsThatWereQA = allItemsForAuthentication.filter(
        (i) => i.Status === "Failed" && i.QCCompleted
      );

      let allPassedItems = allItemsForAuthentication.filter(
        (i) => i.Status !== "Failed"
      );

      if (allFailedItemsThatWereNotQA.length) {
        return Swal.fire(
          "QC Processing",
          "Failed Items Must Go To QC Processing First. Click Ok To QC Failed Items",
          "warning"
        ).then(() => {
          this.handleMultipleItemsQA(allFailedItemsThatWereNotQA);
        });
      }

      if (allFailedItemsThatWereQA.length || allPassedItems.length) {
        if (this.$store.state.user.isSuperAdmin)
          this.showQAAssociateModal = true;
        else this.saveAuthChanges();
      }
    },
    handleMultipleItemsQA(data) {
      this.allFailedItems = data.map((i) => {
        i.qcResponses = JSON.parse(
          JSON.stringify(Object.assign({}, this.qcResponses))
        );
        return i;
      });
      this.popupTab = 0;
      this.showQCModalForMult = true;
    },
    async saveAuthChanges() {
      let loader = this.$loading.show({
        container: null,
        canCancel: false,
      });
      if (!this.selectedQaAssociate) {
        Swal.fire(
          "Select QA Associate",
          `Please select QA Associate before moving forward`,
          "error"
        );
      }

      // const batch = db.batch();
      let orderId = "";
      let allItemsBatches = this.allItemsForAuthentication.map((i) => {
        orderId = i.OrderNum;
        // if (!i.QA_Associate) {
        return db.doc(`orders/${i.OrderNum}/items/${i.id}`).update({
          Status: i.Status,
          UserID: this.activeUser.uid,
          QA_Associate: this.selectedQaAssociate,
          QCCompleted: true,
        });
        // }
      });

      const allOrderItems = await db
        .collection(`orders/${orderId}/items`)
        .get();

      let authenticated_count = allOrderItems.docs.filter(
        (d) => d.data().Status === "Needs Q/A"
      );
      console.log("authenticated_count.length", authenticated_count.length);
      db.doc(`orders/${orderId}`).update({
        Status:
          authenticated_count.length > 0
            ? "Incomplete-QA In Process"
            : "Completed",
        CompletedAt: Date.now(),
      });

      return Promise.all(allItemsBatches)
        .then(() => {
          const certificateAPI = functions.httpsCallable(
            "certificateAPI-updateCertificate"
          );
          try {
            const certsUpdated = this.allItemsForAuthentication.map((item) => {
              return certificateAPI({
                status: item.Status,
                certNum: item.CertNum,
                stockNum: item.ItemNum,
                trackingNum: item.TrackingNum || "",
              });
            });

            return Promise.all(certsUpdated);
          } catch (error) {
            console.log(error);
            return true;
          }
        })
        .then(() => {
          loader.hide();
          Swal.fire(
            "Authenticated",
            `Order #${orderId} has been authenticated`,
            "success"
          );
        })
        .then(() => this.endAuthSequence())
        .catch((err) => {
          Swal.fire("Error", err.message, "success");
          loader.hide();
        });
    },
    closeTableModal() {
      this.selectedOrderLineStatus = [];
      this.allLineItems = [];
      this.allLineItemsUnfiltered = [];
      this.selectedOrder = {};
      this.allItemsForSelectedLine = [];
      this.allFailedItems = [];
      this.selectedLineItem = {};
      this.selectedItem = {};
      this.showDialog = false;
    },
    clearFilters() {
      for (const key in this.filterBy) {
        if (key === "client" || key === "status") {
          this.filterBy[key] = "";
        } else if (key === "startDate" || key === "endDate") {
          this.filterBy[key] = null;
          // this.filterBy[key] = "";
        } else if (
          key === "showFailedItems" ||
          key === "showPassedWithRepair" ||
          key === "showPassedItems"
        ) {
          this.filterBy[key] = false;
        } else {
          this.filterBy[key] = "";
        }
      }
      this.searchVal = null;
      this.resetTable();
    },
    handleAuthSearch() {
      let value = this.searchAuthItems.trim();

      if (!value) {
        this.allItemsForAuthentication = [];
      }
      let orderRef = db.collection(`orders/${value}/items`);
      this.orderBeingAuthenticated = value;
      if (value.includes("-")) {
        value = value.split("-");
        const orderNum = value[0].trim();
        const lineNum = Number(value[1]);
        this.orderBeingAuthenticated = orderNum;
        orderRef = db
          .collection(`orders/${orderNum}/items`)
          .where("LineNum", "==", lineNum);
      }

      orderRef.get().then((res) => {
        this.allItemsForAuthentication = res.docs.map((d) =>
          Object.assign({ id: d.id }, d.data())
        );
        this.$forceUpdate();
      });
    },
    async handleSearch(e) {
      if (e.keyCode == 13 || e == "click") {
        const { selectedSearchBy, searchVal, activeUser } = this;

        if (!searchVal) this.clearFilters();

        let colRef;

        if (selectedSearchBy === "on") {
          //on stands for order number
          colRef = db.doc(`orders/${searchVal}`);
        }
        if (selectedSearchBy === "po") {
          //on stands for order number
          colRef = db
            .collection("orders")
            .where("PurchaseOrders", "array-contains", searchVal);
        } else if (selectedSearchBy === "sku") {
          if (activeUser.isAdmin || activeUser.isSuperAdmin) {
            colRef = db
              .collection("orders")
              .where("ItemSKUs", "array-contains", searchVal);
          } else if (activeUser.clientID) {
            colRef = db
              .collection("orders")
              .where("Client", "==", activeUser.clientID)
              .where("ItemSKUs", "array-contains", searchVal);
          } else if (activeUser.customerID) {
            colRef = db
              .collection("orders")
              .where("Customer", "==", activeUser.customerID)
              .where("ItemSKUs", "array-contains", searchVal);
          }
        } else if (selectedSearchBy === "cert") {
          colRef = db
            .collection("orders")
            .where("ItemCerts", "array-contains", searchVal);
        }

        let docs = await colRef.get();
        let data = [];
        if (docs.size) {
          data = docs.docs.map((doc) =>
            Object.assign({ id: doc.id }, doc.data())
          );
        } else if (docs.exists) {
          docs = Object.assign({ id: docs.id }, docs.data());
          data.push(docs);
        } else {
          Swal.fire("", "No Records Has Been Found", "error");
          data = [];
        }

        this.tableData = data;
      }
    },
    async handleFilter() {
      let container = { docs: [] };
      let loader = this.$loading.show({
        container: null,
        canCancel: false,
      });
      let ref = db.collection("orders");
      if (this.$store.state.user.clientID) {
        ref = ref.where("Client", "==", this.$store.state.user.clientID);
      } else if (this.$store.state.user.customerID) {
        ref = ref.where("Customer", "==", this.$store.state.user.customerID);
      }

      

      if (
        !this.filterBy.showFailedItems &&
        !this.filterBy.showPassedWithRepair &&
        !this.filterBy.showCertificateOrders &&
        !this.filterBy.showPassedItems &&
        !this.filterBy.customers &&
        !this.filterBy.client &&
        !this.filterBy.status &&
        !this.filterBy.startDate &&
        !this.filterBy.endDate
      ) {
        this.resetTable();
        loader.hide();
        return false;
      }

      try {
        for (const key in this.filterBy) {
          if (Object.hasOwnProperty.call(this.filterBy, key)) {
            if (key != "startDate" && key != "endDate") {
              const element = this.filterBy[key];
              if (element) {
                if (key == "client") {
                  ref = ref.where("Client", "==", element);
                }

                if (key == "customers") {
                  ref = ref.where("Customer", "==", element);
                }

                if (key == "status") {
                  ref = ref.where("Status", "==", element);
                }

                if (key == "showCertificateOrders") {
                  ref = ref.where(
                    "ServiceType",
                    "==",
                    "Screening,QA,Certificate,DropShip"
                  );
                }

                if (key == "showPassedWithRepair") {
                  ref = ref
                    .orderBy("PassedWithRepairsItems", "asc")
                    .where("PassedWithRepairsItems", ">", 0);
                }

                if (key == "showFailedItems") {
                  ref = ref
                    .orderBy("FailedItems", "asc")
                    .where("FailedItems", ">", 0);
                }
              }
            }
          }
        }

        if (
          this.filterBy.startDate &&
          this.filterBy.endDate &&
          !this.filterBy.showFailedItems &&
          !this.filterBy.showPassedWithRepair &&
          !this.filterBy.showCertificateOrders &&
          !this.filterBy.showPassedItems &&
          !this.filterBy.customers &&
          !this.filterBy.client &&
          !this.filterBy.status
        ) {
          let startDateObj =
            typeof this.filterBy.startDate == "object"
              ? DateTime.fromJSDate(this.filterBy.startDate)
              : DateTime.fromISO(this.filterBy.startDate);

          let endDateObj =
            typeof this.filterBy.endDate == "object"
              ? DateTime.fromJSDate(this.filterBy.endDate)
              : DateTime.fromISO(this.filterBy.endDate);

          let startDate = startDateObj
            .setZone("America/New_York")
            .startOf("day")
            .toMillis();

          let endDate = endDateObj
            .setZone("America/New_York")
            .endOf("day")
            .toMillis();

          ref = ref
            .orderBy("Timestamp", "desc")
            .startAt(endDate)
            .endAt(startDate);
          // .startAt(startDate)
          // .endAt(endDate);

          container = await ref.get();
          if (!container.docs.length) {
            loader.hide();
            return Swal.fire("Empty Search Records");
          }
          this.searchedData = container.docs.map((d) =>
            Object.assign({ id: d.id }, d.data())
          );
        } else {
          let startDateObj =
            typeof this.filterBy.startDate == "object"
              ? DateTime.fromJSDate(this.filterBy.startDate)
              : DateTime.fromISO(this.filterBy.startDate);

          let endDateObj =
            typeof this.filterBy.endDate == "object"
              ? DateTime.fromJSDate(this.filterBy.endDate)
              : DateTime.fromISO(this.filterBy.endDate);

          let startDate = startDateObj
            .setZone("America/New_York")
            .startOf("day")
            .toMillis();

          let endDate = endDateObj
            .setZone("America/New_York")
            .endOf("day")
            .toMillis();

          container = await ref.get();
          if (!container.docs.length) {
            loader.hide();
            return Swal.fire("Empty Search Records");
          }
          this.searchedData = container.docs
            .filter(
              (doc) =>
                doc.data().Timestamp >= startDate &&
                doc.data().Timestamp <= endDate
            )
            .map((d) => Object.assign({ id: d.id }, d.data())).sort((a,b) => b.Timestamp - a.Timestamp);
        }

        loader.hide();
      } catch (error) {
        console.error(error);
        loader.hide();
        return Swal.fire(
          "Internal Issue",
          `Oops there seems to be an issue please try again or contact DIS support`,
          "warning"
        );
      }
    },
    validateDateRange(key) {
      if (moment(this.filterBy.startDate).isAfter(this.filterBy.endDate)) {
        this.filterBy.endDate = null;
      }

      if (key === "startDate") {
        this.filterBy.endDate = this.filterBy.startDate;
      }
    },
    async openQC(item) {
      this.showModalContentForItem = false;
      if (item) this.selectedItem = item;

      let qcResponsesRef = await db
        .doc(
          `orders/${this.selectedOrder.id}/quality_control/${this.selectedItem.ItemNum}`
        )
        .get();

      if (qcResponsesRef.exists) {
        this.qcResponses = qcResponsesRef.data();
        if (!this.qcResponses.overall) {
          this.qcResponses = Object.assign(this.qcResponses, {
            overall: {
              a1: {
                question: "Product Status - if listed as Pre-Launch, do not QC",
                value: false,
              },
              a2: {
                question: "Product matches photo",
                value: false,
              },
              a3: {
                question: "Other",
                value: false,
              },
            },
            stones: {
              a1: {
                question: "Stone Count",
                value: false,
              },
              a2: {
                question: "Stone size",
                value: false,
              },
              a3: {
                question: "Stone clarity",
                value: false,
              },
              a4: {
                question: "Stone color",
                value: false,
              },
            },
            rings: {
              a1: {
                question: "Size",
                value: false,
              },
              a2: {
                question: "Shank height/width",
                value: false,
              },
              a3: {
                question: "Rhodium + color (if applicable)",
                value: false,
              },
              a4: {
                question: "Prong count",
                value: false,
              },
              a5: {
                question: "Metal type",
                value: false,
              },
            },
            earrings: {
              a1: {
                question: "Length/Width",
                value: false,
              },
              a2: {
                question: "Rhodium + color (if applicable)",
                value: false,
              },
              a3: {
                question: "Backing Type",
                value: false,
              },
            },
          });
        }
      }

      this.showQCModal = true;
    },
    clearQCResponses() {
      this.showQCModal = false;
      this.leftQCPanelInit = false;
      this.qcResponses = {
        overall: {
          a1: {
            question: "Product Status - if listed as Pre-Launch, do not QC",
            value: false,
          },
          a2: {
            question: "Product matches photo",
            value: false,
          },
          a3: {
            question: "Other",
            value: false,
          },
        },
        stones: {
          a1: {
            question: "Stone Count",
            value: false,
          },
          a2: {
            question: "Stone size",
            value: false,
          },
          a3: {
            question: "Stone clarity",
            value: false,
          },
          a4: {
            question: "Stone color",
            value: false,
          },
        },
        rings: {
          a1: {
            question: "Size",
            value: false,
          },
          a2: {
            question: "Shank height/width",
            value: false,
          },
          a3: {
            question: "Rhodium + color (if applicable)",
            value: false,
          },
          a4: {
            question: "Prong count",
            value: false,
          },
          a5: {
            question: "Metal type",
            value: false,
          },
        },
        earrings: {
          a1: {
            question: "Length/Width",
            value: false,
          },
          a2: {
            question: "Rhodium + color (if applicable)",
            value: false,
          },
          a3: {
            question: "Backing Type",
            value: false,
          },
        },
        rejections: [],
      };
    },
    validateQCResponses(val) {
      let valid = false;

      if (val.rejections.length || this.leftQCPanelInit) {
        valid = true;
      }

      return valid;
    },
    handleQCChanges() {
      this.qcResponses.createdBy = this.activeUser.uid;
      if (this.validateQCResponses(this.qcResponses))
        return db
          .doc(
            `orders/${this.selectedOrder.id}/quality_control/${this.selectedItem.ItemNum}`
          )
          .set(this.qcResponses, { merge: true })
          .then(() => {
            const certificateAPI = functions.httpsCallable(
              "certificateAPI-updateCertificate"
            );

            try {
              return certificateAPI({
                status: this.selectedItem.Status,
                certNum: this.selectedItem.CertNum,
                stockNum: this.selectedItem.ItemNum,
                trackingNum: this.selectedItem.TrackingNum || "",
              });
            } catch (error) {
              console.log(error);
              return true;
            }
          })
          .then(() => {
            this.clearQCResponses();
          });
      else
        return Swal.fire(
          "",
          "No changes has been detected, cannot continue without proper Q/A",
          "error"
        );
    },
    selectedLineItemAction(item) {
      this.showLineItemModal = true;
      this.selectedLineItem = item;
      this.allItemsForSelectedLine = this.allLineItems.filter(
        (i) => i.LineNum == item.LineNum
      );
      this.showModalContentForLineItem = true;
      this.showModalContentForItem = false;
      this.showDialog = false;
    },
    selectedItemAction(item) {
      this.showLineItemModal = false;
      this.selectedItem = item;
      this.showModalContentForLineItem = false;
      this.showModalContentForItem = true;
      this.showDialog = false;
    },
    handleViewModal(item) {
      this.selectedOrder = item;
      this.selectedOrderLineStatus = item.LineItems;
      this.$bind(
        "allLineItemsUnfiltered",
        db.collection(`orders/${item.id}/items`)
      );
      this.allLineItems = this.allLineItemsUnfiltered;
      this.showDialog = true;
    },
    handleAuthViewModal(item) {
      this.selectedOrder = item;
      this.selectedOrderLineStatus = item.LineItems;
      this.$bind(
        "allLineItemsUnfiltered",
        db.collection(`orders/${item.id}/items`)
      );
      this.allLineItems = this.allLineItemsUnfiltered;
      this.showAuthViewModal = true;
    },
    closeAuthViewModal() {
      this.selectedOrder = {};
      this.selectedOrderLineStatus = [];
      this.showAuthViewModal = false;
    },
  },
};
</script>
<style scoped>
.md-layout-item {
  width: 100%;
  position: absolute;
}
.toggle_tabs {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  padding-bottom: 0;
  width: 97%;
  overflow: hidden;
  overflow-x: auto;
  margin: auto;
  margin-bottom: 20px;
}

.toggle_tabs .tab {
  cursor: pointer;
  border: 2px solid #eaeaea;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1% 3%;
  border-radius: 0;
  background: transparent;
  border: none;
  font-weight: bold;
  border-bottom: 3px solid #eaeaea;
  text-transform: uppercase;
  color: rgba(27, 105, 111, 0.7);
}
.results {
  height: 100px;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: center;
}

.action-btns {
  display: flex;
}

.content {
  max-width: 100px;
}

.notes {
  max-height: 100px;
  width: 150px;
  overflow-y: auto;
  overflow-x: hidden;
}
.toggle_tabs .tab.active {
  background: #0024d6 !important;
  border-bottom: 3px solid #1f479a;
  padding: 1% 3%;
}
.toggle_tabs .tab.active p {
  color: white !important;
  font-size: 16px !important;
  font-weight: 700 !important;
}

.toggle_tabs .tab p {
  margin: 0;
  font-size: 16px !important;
  font-weight: 700 !important;
  padding: 1% 3%;
}

.search-options {
  width: auto;
  position: absolute;
  top: -11px;
  left: 50px;
}
</style>
