<template>
  <div>
    <md-dialog
      @md-clicked-outside="$emit('close')"
      :md-active.sync="showDialog"
    >
      <md-dialog-content>
        <md-button
          class="md-icon-button"
          v-if="showBackBtn"
          @click="$emit('back')"
        >
          <md-icon> arrow_back </md-icon>
          <md-tooltip>Back</md-tooltip>
        </md-button>
        <md-button class="md-accent md-right md-icon-button" @click="$emit('close')">
          <md-icon> close </md-icon>
        </md-button>
        <main>
          <slot name="modalContent"></slot>
        </main>
      </md-dialog-content>
      <md-dialog-actions>
        <slot name="dialog-actions"></slot>

        <md-button v-if="showSaveBtn" class="md-primary" @click="$emit('save')"
          >Save</md-button
        >
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: {
    showSaveBtn: Boolean,
    showBackBtn: Boolean,
    showDialog: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="css" scoped>
.md-dialog /deep/.md-dialog-container {
  max-width: 950px;
}

.md-right {
  position: absolute !important;
  right: 0 !important;
  top: 0 !important;
}
</style>
