<template>
  <div>
    <md-dialog
      @md-clicked-outside="$emit('close-modal')"
      :md-active.sync="showDialog"
    >
      <!-- <md-dialog-title>{{ title }}</md-dialog-title> -->
      <md-button class="md-accent md-right md-icon-button" @click="$emit('close-modal')">
        <md-icon> close </md-icon>
      </md-button>
      <md-tabs md-dynamic-height>
        <md-tab md-label="Item Status">
          <Table
            :showFilter="showFilterForItemTable"
            :showItemFilter="showItemFilter"
            @selected-content="$emit('selectedItemAction', $event)"
            :tableData.sync="lineItems"
            :tableInfo="itemsInfo"
            :showQCBtn="true"
            @handleSearch="$emit('handleSearchForIndividualItem', $event)"
            @open-qc="$emit('open-qc', $event)"
            @qc-mult="$emit('qc-mult', $event)"
          ></Table>
        </md-tab>
        <!-- <md-tab md-label="Order Line Status">
          <Table
            :showFilter="showFilterForLineItemTable"
            :tableData.sync="orderLineStatus"
            :tableInfo="lineItemsInfo"
            @selected-content="$emit('selectedLineItemAction', $event)"
          ></Table>
        </md-tab> -->
        
      </md-tabs>

      <md-dialog-actions>
        <!-- <md-button class="md-primary" @click="showDialog = false"
          >Save</md-button
        > -->
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
import Table from "./Table.vue";

export default {
  name: "TableModal",
  props: {
    showFilterForLineItemTable: Boolean,
    showFilterForItemTable: Boolean,
    showItemFilter: Boolean,
    showLineItemFilter: Boolean,
    orderLineStatus: {
      type: Array,
      default: () => [],
    },
    lineItems: {
      type: Array,
      default: () => [],
    },
    showDialog: {
      type: Boolean,
      default: false,
    },
    lineItemsInfo: {
      type: Array,
      default: () => [],
    },
    itemsInfo: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Table,
  },
};
</script>

<style lang="css" scoped>
.md-dialog /deep/.md-dialog-container {
  max-width: 950px;
  min-width: 800px !important;
}

.md-right {
  position: absolute !important;
  right: 0 !important;
  top: 0 !important;
}
</style>
